@font-face {
  font-family: "Aeonik";
  src: url("/assets/fonts/Aeonik/Aeonik-Regular.woff2") format("woff2"),
    url("/assets/fonts/Aeonik/Aeonik-Regular.woff") format("woff"),
    url("/assets/fonts/Aeonik/Aeonik-Regular.eot") format("eot"),
    url("/assets/fonts/Aeonik/Aeonik-Regular.ttf") format("ttf");
  font-style: normal;
  font-display: swap;
}
$aeonik: "Aeonik-Regular";

@font-face {
  font-family: "Aeonik";
  src: url("/assets/fonts/Aeonik/Aeonik-Medium.woff2") format("woff2"),
    url("/assets/fonts/Aeonik/Aeonik-Medium.woff2") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

$aeonikMedium: "Aeonik-Medium";
