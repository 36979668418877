//400px - 576px
@mixin mobile {
  @media screen and (min-width: $breakpoint-ss) {
    @content;
  }
}
//576px - 768px
@mixin mobile-landscape {
  @media screen and (min-width: $breakpoint-s) {
    @content;
  }
}
//768px - 835px
@mixin tablet-portrait {
  @media screen and (min-width: $breakpoint-ms) {
    @content;
  }
}
//835px - 992px
@mixin tablet-landscape-s {
  @media screen and (min-width: $breakpoint-m) {
    @content;
  }
}
//992px - 1194px
@mixin tablet-landscape {
  @media screen and (min-width: $breakpoint-ls) {
    @content;
  }
}
//992px - 1194px
@mixin desktop {
  @media screen and (min-width: $breakpoint-l) {
    @content;
  }
}
//1440px - 1920px
@mixin desktop-xl {
  @media screen and (min-width: $breakpoint-xl) {
    @content;
  }
}
//1920px - 2560px
@mixin desktop-HD {
  @media screen and (min-width: $breakpoint-xxl) {
    @content;
  }
}