
.wrapperFreeTrial {
  
      &::-webkit-scrollbar {
           display: none;
       }

    @include tablet-landscape {
        display: block;
        margin: 0 auto;
        max-width: 700px;
    }
  }
  
  .cardFreeTrial {
    @include tablet-landscape {
    display: block;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
    color: #333;
    padding: 40px;
    margin: 20px 0;
    }
  }

  .cardFreeTrial {
    @include tablet-landscape {
    position: sticky;
    top: 20px;
    
    }
  }
  

  @for $i from 1 through 4 {
    @include tablet-landscape {
    .cardFreeTrial:nth-child(#{$i}n) {
      top: $i * 30px;
    }
    
    }
  }