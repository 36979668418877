$colorPrimaryLight: #006398;
$colorPrimaryInverseLight: #25beb5;
$colorOnPrimaryLight: #ffffff;
$colorPrimaryContainerLight: #71f7ec;
$colorOnPrimaryContainerLight: #001d32;
$colorSecondaryLight: #006a64;
$colorOnSecondaryLight: #ffffff;
$colorSecondaryContainerLight: #71f7ec;
$colorOnSecondaryContainerLight: #00201e;
$colorTertiaryLight: #48617a;
$colorOnTertiaryLight: #ffffff;
$colorTertiaryContainerLight: #cfe5ff;
$colorOnTertiaryContainerLight: #001d34;
$colorErrorLight: #ba1a1a;
$colorOnErrorLight: #ffffff;
$colorErrorContainerLight: #ffdad6;
$colorOnErrorContainerLight: #410002;
$colorOutlineLight: #6f7977;
$colorBackgroundLight: #fafdfb;
$colorOnBackgroundLight: #191c1c;
$colorSurfaceLight: #eff2f4;
$colorSurface1Light: #e5e9ed;
$colorSurface2Light: #e5e9ed;
$colorSurface3Light: #dfe3e8;
$colorSurface4Light: #dce1e7;
$colorSurface5Light: #d7dde3;
$colorOnSurfaceLight: #191c1c;
$colorSurfaceVariantLight: #dae5e2;
$colorOnSurfaceVariantLight: #3f4947;
$colorInverseSurfaceLight: #2d3130;
$colorInverseOnSurfaceLight: #eff1f0;
$colorPrimaryDark: #94ccff;
$colorOnPrimaryDark: #003734;
$colorPrimaryContainerDark: #00504b;
$colorOnPrimaryContainerDark: #71f7ec;
$colorSecondaryDark: #b0ccc8;
$colorOnSecondaryDark: #1c3532;
$colorSecondaryContainerDark: #324b49;
$colorOnSecondaryContainerDark: #cce8e4;
$colorTertiaryDark: #afc9e7;
$colorOnTertiaryDark: #18324a;
$colorTertiaryContainerDark: #304962;
$colorOnTertiaryContainerDark: #cfe5ff;
$colorErrorDark: #ffb4ab;
$colorOnErrorDark: #690005;
$colorErrorContainerDark: #93000a;
$colorOnErrorContainerDark: #ffdad6;
$colorOutlineDark: #899391;
$colorBackgroundDark: #191c1c;
$colorOnBackgroundDark: #e0e3e2;
$colorSurfaceDark: #071d30;
$colorSurface1Dark: #11293b;
$colorSurface2Dark: #142f43;
$colorSurface3Dark: #17333a;
$colorSurface4Dark: #193546;
$colorSurface5Dark: #1b3949;
$colorOnSurfaceDark: #e0e3e2;
$colorSurfaceVariantDark: #3f4947;
$colorOnSurfaceVariantDark: #bec9c6;
$colorInverseSurfaceDark: #e0e3e2;
$colorInverseOnSurfaceDark: #191c1c;
$colorOnPrimaryContainerLightalt: #475b68;

:export {
  background: #fcfcff;
  colorPrimaryLight: #006a64;
  colorPrimaryInverseLight: #4fdad2;
  colorOnPrimaryLight: #ffffff;
  colorPrimaryContainerLight: #71f7ec;
  colorOnPrimaryContainerLight: #031f32;
  colorSecondaryLight: #4a6360;
  colorOnSecondaryLight: #ffffff;
  colorSecondaryContainerLight: #cce8e4;
  colorOnSecondaryContainerLight: #051f1d;
  colorTertiaryLight: #48617a;
  colorOnTertiaryLight: #ffffff;
  colorTertiaryContainerLight: #cfe5ff;
  colorOnTertiaryContainerLight: #001d34;
  colorErrorLight: #ba1a1a;
  colorOnErrorLight: #ffffff;
  colorErrorContainerLight: #ffdad6;
  colorOnErrorContainerLight: #410002;
  colorOutlineLight: #6f7977;
  colorBackgroundLight: #fafdfb;
  colorOnBackgroundLight: #191c1c;
  colorSurfaceLight: #eff2f4;
  colorOnSurfaceLight: #191c1c;
  colorSurfaceVariantLight: #dae5e2;
  colorOnSurfaceVariantLight: #3f4947;
  colorInverseSurfaceLight: #2d3130;
  colorInverseOnSurfaceLight: #eff1f0;
  colorPrimaryDark: #50dbd0;
  colorOnPrimaryDark: #003734;
  colorPrimaryContainerDark: #00504b;
  colorOnPrimaryContainerDark: #71f7ec;
  colorSecondaryDark: #b0ccc8;
  colorOnSecondaryDark: #1c3532;
  colorSecondaryContainerDark: #324b49;
  colorOnSecondaryContainerDark: #cce8e4;
  colorTertiaryDark: #afc9e7;
  colorOnTertiaryDark: #18324a;
  colorTertiaryContainerDark: #304962;
  colorOnTertiaryContainerDark: #cfe5ff;
  colorErrorDark: #ffb4ab;
  colorOnErrorDark: #690005;
  colorErrorContainerDark: #93000a;
  colorOnErrorContainerDark: #ffdad6;
  colorOutlineDark: #899391;
  colorBackgroundDark: #191c1c;
  colorOnBackgroundDark: #e0e3e2;
  colorSurfaceDark: #191c1c;
  colorOnSurfaceDark: #e0e3e2;
  colorSurfaceVariantDark: #3f4947;
  colorOnSurfaceVariantDark: #bec9c6;
  colorInverseSurfaceDark: #e0e3e2;
  colorInverseOnSurfaceDark: #191c1c;
  colorSurface3Light: #dfe3e8;
  colorSurface5Dark: #1b3949;
  colorInfo: #cbe5ff;
  colorWarning: #ffdcbc;
  colorOnPrimaryContainerLightalt: #475b68;
}
