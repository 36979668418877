.All:hover,
.All-selected {
  color: var(--on-primary-dark);
  background-color: #fdfcff !important;
}

.Webinar:hover,
.Webinar-selected {
  color: var(--ce-color-sys-light-on-tertiary-container, #2d1600);
  background-color: #ffdcc0 !important;
}

.Article:hover,
.Article-selected {
  color: var(--ce-color-sys-light-surface, #fdfcff);
  background: var(--ce-color-ref-primary-primary-60, #4397d6) !important;
}

.Event:hover,
.Event-selected {
  color: var(--ce-color-sys-light-surface, #fdfcff);
  background-color: #94ccff !important;
}

.News:hover,
.News-selected {
  color: var(--ce-color-sys-light-surface, #fdfcff);
  background-color: #42aaa3 !important;
}

.Whitepaper:hover,
.Whitepaper-selected {
  color: var(--ce-color-sys-light-surface, #fcfdff);
  background-color: #198038 !important;
}

.Topic-default {
  height: auto;
  width: min-content;
  max-width: 110px;
  padding: 0.2rem 0.2rem;
}
