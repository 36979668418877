@charset "UTF-8";

// 1. Abstracts (variables, functions, mixins, resets)
@import "abstracts/all";

// 2. Base (typography, etc.)
@import "ui-kit/all";

.grecaptcha-badge {
  opacity: 0;
}
