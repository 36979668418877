/* Definimos las Custom properties */
:root {
  /*animation*/
  // .animate__animated.animate__fadeIn {
  //   --animate-duration: 1s;

  // }
  /* Colores */
  --primary-color: #031f32;
  --secundary-color: #ffffff;

  //  .MuiFilledInput-root {
  //   background-color: #EDEFF3;
  //   }

  //change label color and border color
  // .MuiInputLabel-root.Mui-focused {
  //   color: #006398;
  //   border-color: #006398;
  // }

  //input color when is filled
  // .MuiFilledInput-underline:after {
  //   border-bottom-color: #006398;
  // }

  //input color autoComplete
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }

  //style button root

  .MuiButton-root {
    border-radius: 100px;

    text-transform: none !important;
    &:first-letter {
      text-transform: capitalize;
    }
    & span {
      text-transform: lowercase;
    }
    & span:first-of-type {
      text-transform: capitalize;
    }
  }

  .MuiButton-endIcon {
    margin-left: 0;
    padding: 0;
  }

  .MuiButton-startIcon {
    margin-right: 0;
    padding: 0;
  }
}

/*Las adaptamos al modo oscuro */
@media (prefers-color-scheme: dark) {
  :root {
    --primary-color: #94ccff;
    --secundary-color: #003352;
  }
}

/*Configuramos si un usuario ha activado el modo alto contraste. (WD) */
@media (prefers-contrast: hight) {
  :root {
    --primary-color: #000000;
    --secundary-color: #fafafa;
  }
}

/* Desactivamos los animations en el caso de que el usuario haya configurado el modo sin animation */
/* cambiar * por etiquetas que use animadas */
@media (prefers-reduced-motion: reduce) {
  * {
    animation: none !important;
    transition: none !important;
  }
}

/* Reseteamos los margin y paddings de todas las etiquetas */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  vertical-align: baseline;
}
*::before,
*::after {
  display: block;
}

/* Evitamos problemas con las imagenes */
img,
picture,
video,
iframe,
figure {
  max-width: 100%;
  width: 100%;
  display: block;
}

/* Reseteamos los enlaces para funcionar como cajas... */
a {
  display: block;
  text-decoration: none;
  color: inherit;
  font-size: inherit;
}
/* ... excepto los que se encuentran en párrafos */
p a {
  display: inline;
}

/* Quitamos los puntos de los <li> */
li {
  list-style-type: none;
}

/* (Opcional) Configuramos anclas suaves */
html {
  scroll-behavior: smooth;
  // font-size: max(16px,0.833vw);
}

/* Desactivamos estilos por defecto de las principales etiquetas de texto */
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
a,
strong,
blockquote,
i,
b,
u,
em {
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
  text-decoration: none;
  color: inherit;
}

em {
  font-style: italic;
}

strong,
b {
  font-weight: 500;
}

/* Evitamos problemas con los pseudoelementos de quotes */
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

/* (Opcional) Configuramos el texto que seleccionamos */
::selection {
  background-color: var(--secundary-color);
  color: var(--primary-color);
}

/* Nivelamos problemas de tipografías y colocación de formularios */
form,
input,
textarea,
select,
button,
label {
  font-family: inherit;
  font-size: inherit;
  hyphens: auto;
  background-color: transparent;
  color: inherit;
  display: block;
}

/* Reseteamos las tablas */
table,
tr,
td {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Evitamos problemas con los SVG */
svg {
  display: block;
  // fill: currentColor;
}

/* Configuramos la tipografía para toda la web */
body {
  position: relative;
  font-weight: 400;
  min-height: 100vh;
  font-size: 100%;
  font-family: var(--Typescale-font);
  color: #fdfcfc;
  background-color: #031f32 !important;
  line-height: 1.4em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  transition: background-color 0.5s ease;
  margin: 0;
  padding: 0;

  &.yarl__no_scroll {
    overflow: auto;
    overscroll-behavior: "unset";
  }
}
