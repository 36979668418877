// //+++++++++++++ version slide v2 ++++++++++++++

.vision-slide2 {
  padding: 0 !important;

  img {
    display: none;
  }
  .slider-text {
    color: transparent;
    transition: all 0.2s ease !important;
  }
}

.vision-slide2.swiper-slide-active {
  .slider-text {
    color: #ebece6;
    transition: all 0.2s ease !important;
  }
  img {
    display: block;
  }
}

.vision-slide2.swiper-slide-prev {
  img {
    display: block;
    filter: blur(2px) !important;
  }
  button {
    display: block;
  }
  @media (min-width: 1024px) {
    height: fit-content !important;
  }
}

.vision-slide2.swiper-slide-next {
  img {
    display: block;
    filter: blur(2px) !important;
  }
  button {
    display: block;
  }
  @media (min-width: 1024px) {
    height: fit-content !important;
  }
}

// //+++++++++++++++++++++++++++

.swiper {
  @media (min-width: 850px) {
    padding: 2rem;
  }
}

// // //+++++ slider controler +++++

.slider-controler {
  position: relative;
  bottom: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-controler .swiper-button-next {
  left: 55% !important;
}

.slider-controler .swiper-button-prev {
  left: 45% !important;
}

@media (max-width: 990px) {
  .slider-controler .swiper-button-next {
    left: 55% !important;
  }
}

@media (max-width: 450px) {
  .slider-controler .swiper-button-next {
    left: 55% !important;
  }
}

@media (max-width: 990px) {
  .slider-controler .swiper-button-prev {
    left: 45% !important;
  }
}

@media (max-width: 450px) {
  .slider-controler .swiper-button-prev {
    left: 45% !important;
  }
}

.slider-controler .slider-arrow {
  border-radius: 50%;
  transform: translateX(-42%);
  color: var(--on-surface-dark);
}

.slider-controler .slider-arrow .MuiSvgIcon-root {
  font-size: 0.8rem;
}
.slider-controler .slider-arrow::after {
  content: "";
}

// ++++++++ pagination dots ++++++++
.swiper-pagination-bullet {
  background: #475b68 !important;
  width: 0.625rem !important;
  height: 0.625rem !important;
  border-radius: 5px !important;
  margin: 16px 5px 0 !important;
}

.swiper-pagination-bullet-active {
  background: #52d4cc !important;
  width: 1.875rem !important;
  height: 0.625rem !important;
  border-radius: 5px !important;
  margin: 16px 5px 0 !important;
}

// ++++++++ bubble slider ++++++++

.swiper-slide.swiper-container {
  background-position: center;
  background-size: cover;
  width: 350px;
  height: auto;

  @media (min-width: 1440px) {
    width: 380px;
  }
}

.swiper-container.swiper-slide-prev {
  filter: brightness(0.5) !important;
}

.swiper-container.swiper-slide-next {
  filter: brightness(0.5) !important;
}

// ++++++++ quotes slider ++++++++

.swiper-slide.versatile-slide {
  background-position: center;
  background-size: cover;
  margin-top: 20px;
  width: 306px;
  height: auto;

  @media (min-width: 1024px) {
    width: 358px;
  }
}
.versatile-slide.swiper-slide-prev {
  filter: brightness(0.5) !important;
}

.versatile-slide.swiper-slide-next {
  filter: brightness(0.5) !important;
}
