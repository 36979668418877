.hide-mobile {
  display: none;
  @include desktop_xl {
    display: block;
  }
}

.hide-desktop {
  @include desktop_xl {
    display: none;
  }
}

.weight-medium {
  font-family: var(--Typescale-font-medium) !important;
  font-weight: 500;
}
.button-small {
  font-family: var(--Typescale-font-medium) !important;
  font-weight: 500;
  font-size: 14px !important;
  line-height: 22px;
  letter-spacing: 0.46px;
  padding: 6px 16px !important;
  font-weight: 500;
}

.button {
  font-family: var(--Typescale-font-medium) !important;
  font-weight: 500;
  font-size: 18px !important;
  line-height: 18px;
  letter-spacing: 0.2px;
  font-weight: 500;
  @include tablet-landscape {
    font-size: 18px !important;
    line-height: 18px;
    letter-spacing: 0.2px;
  }
  @include desktop-xl {
    font-size: 18px !important;
    line-height: 18px;
    letter-spacing: 0.2px;
  }
}

.headline1,
.display-small {
  font-family: var(--Typescale-font);
  font-weight: var(--Typescale-weight);
  font-size: var(--Typescale-DS-XS-size);
  line-height: var(--Typescale-DS-line-height);
  letter-spacing: var(--Typescale-DS-tracking);
  @include tablet-landscape {
    font-size: var(--Typescale-DS-L-size);
  }
  @include desktop-xl {
    font-size: var(--Typescale-DS-XL-size);
  }
  // @include desktop-HD {
  //   font-size: var(--Typescale-DS-XXL-size);
  // }
}

.display3,
.display-medium {
  font-family: var(--Typescale-font);
  font-weight: var(--Typescale-weight);
  line-height: var(--Typescale-DM-line-height);
  letter-spacing: var(--Typescale-DM-tracking);
  font-size: var(--Typescale-DM-XS-size);
  @include tablet-landscape {
    font-size: var(--Typescale-DM-L-size);
  }
  @include desktop-xl {
    font-size: var(--Typescale-DM-XL-size);
  }
  // @include desktop-HD {
  //   font-size: var(--Typescale-DM-XXL-size);
  // }
}

.display2,
.display-large {
  font-family: var(--Typescale-font);
  font-weight: var(--Typescale-weight);
  font-size: var(--Typescale-DL-XS-size);
  line-height: var(--Typescale-DL-line-height);
  letter-spacing: var(--Typescale-DL-tracking);
  @include tablet-landscape {
    font-size: var(--Typescale-DL-L-size);
  }
  @include desktop-xl {
    font-size: var(--Typescale-DL-XL-size);
  }
  // @include desktop-HD {
  //   font-size: var(--Typescale-DL-XXL-size);
  // }
}

.label-small {
  font-family: var(--Typescale-font);
  font-weight: var(--Typescale-weight);
  font-size: var(--Typescale-LS-XS-size);
  line-height: var(--Typescale-LS-line-height);
  letter-spacing: var(--Typescale-LS-tracking);
  @include tablet-landscape {
    font-size: var(--Typescale-LS-L-size);
  }
  @include desktop-xl {
    font-size: var(--Typescale-LS-XL-size);
  }
  // @include desktop-HD {
  //   font-size: var(--Typescale-LS-XXL-size);
  // }
}
.overline,
.label-medium {
  font-family: var(--Typescale-font);
  font-weight: var(--Typescale-weight);
  font-size: var(--Typescale-LM-XS-size);
  line-height: var(--Typescale-LM-line-height);
  letter-spacing: var(--Typescale-LM-tracking);
  @include tablet-landscape {
    font-size: var(--Typescale-LM-L-size);
  }
  @include desktop-xl {
    font-size: var(--Typescale-LM-XL-size);
  }
  // @include desktop-HD {
  // font-size: var(--Typescale-LM-XXL-size);
  // }
}
.button,
.label-large {
  font-family: var(--Typescale-font);
  font-weight: var(--Typescale-weight);
  font-size: var(--Typescale-LL-XS-size);
  line-height: var(--Typescale-LL-line-height);
  letter-spacing: var(--Typescale-LL-tracking);
  @include tablet-landscape {
    font-size: var(--Typescale-LL-L-size);
  }
  @include desktop-xl {
    font-size: var(--Typescale-LL-XL-size);
  }
  // @include desktop-HD {
  // font-size: var(--Typescale-LL-XXL-size);
  // }
}

.subhead2,
.title-small {
  font-family: var(--Typescale-font);
  font-weight: var(--Typescale-weight);
  font-size: var(--Typescale-TS-XS-size);
  line-height: var(--Typescale-TS-line-height);
  letter-spacing: var(--Typescale-TS-tracking);
  @include tablet-landscape {
    font-size: var(--Typescale-TS-L-size);
  }
  @include desktop-xl {
    font-size: var(--Typescale-TS-XL-size);
  }
  // @include desktop-HD {
  // font-size: var(--Typescale-TS-XXL-size);
  // }
}

.subhead1,
.title-medium {
  font-family: var(--Typescale-font);
  font-weight: var(--Typescale-weight);
  font-size: var(--Typescale-TM-XS-size);
  line-height: var(--Typescale-TM-line-height);
  letter-spacing: var(--Typescale-TM-tracking);
  @include tablet-landscape {
    font-size: var(--Typescale-TM-L-size);
  }
  @include desktop-xl {
    font-size: var(--Typescale-TM-XL-size);
  }
  // @include desktop-HD {
  // font-size: var(--Typescale-TM-XXL-size);
  // }
}

.headline5,
.title-large {
  font-family: var(--Typescale-font);
  font-weight: var(--Typescale-weight);
  font-size: var(--Typescale-TL-XS-size);
  line-height: var(--Typescale-TL-line-height);
  letter-spacing: var(--Typescale-TL-tracking);
  @include tablet-landscape {
    font-size: var(--Typescale-TL-L-size);
  }
  @include desktop-xl {
    font-size: var(--Typescale-TL-XL-size);
  }
  // @include desktop-HD {
  // font-size: var(--Typescale-TL-XXL-size);
  // }
}

.caption,
.body-small {
  font-family: var(--Typescale-font);
  font-weight: var(--Typescale-weight);
  font-size: var(--Typescale-BS-XS-size);
  line-height: var(--Typescale-BS-line-height);
  letter-spacing: var(--Typescale-BS-tracking);
  @include tablet-landscape {
    font-size: var(--Typescale-BS-L-size);
  }
  @include desktop-xl {
    font-size: var(--Typescale-BS-XL-size);
  }
  // @include desktop-HD {
  // font-size: var(--Typescale-BS-XXL-size);
  // }
}

.body2,
.body-medium {
  font-family: var(--Typescale-font);
  font-weight: var(--Typescale-weight);
  font-size: var(--Typescale-BM-XS-size);
  line-height: var(--Typescale-BM-line-height);
  letter-spacing: var(--Typescale-BM-tracking);
  @include tablet-landscape {
    font-size: var(--Typescale-BM-L-size);
  }
  @include desktop-xl {
    font-size: var(--Typescale-BM-XL-size);
  }
  // @include desktop-HD {
  // font-size: var(--Typescale-BM-XXL-size);
  // }
}

.body1,
.body-large {
  font-family: var(--Typescale-font);
  font-weight: var(--Typescale-weight);
  font-size: var(--Typescale-BL-XS-size);
  line-height: var(--Typescale-BL-line-height);
  letter-spacing: var(--Typescale-BL-tracking);
  @include tablet-landscape {
    font-size: var(--Typescale-BL-L-size);
  }
  @include desktop-xl {
    font-size: var(--Typescale-BL-XL-size);
  }
  // @include desktop-HD {
  // font-size: var(--Typescale-BL-XXL-size);
  // }
}

.headline4,
.headline-small {
  font-family: var(--Typescale-font);
  font-weight: var(--Typescale-weight);
  font-size: var(--Typescale-HS-XS-size);
  line-height: var(--Typescale-HS-line-height);
  letter-spacing: var(--Typescale-HS-tracking);
  @include tablet-landscape {
    font-size: var(--Typescale-HS-L-size);
  }
  @include desktop-xl {
    font-size: var(--Typescale-HS-XL-size);
  }
  // @include desktop-HD {
  // font-size: var(--Typescale-HS-XXL-size);
  // }
}

.headline3,
.headline-medium {
  font-family: var(--Typescale-font);
  font-weight: var(--Typescale-weight);
  font-size: var(--Typescale-HM-XS-size);
  line-height: var(--Typescale-HM-line-height);
  letter-spacing: var(--Typescale-HM-tracking);
  @include tablet-landscape {
    font-size: var(--Typescale-HM-L-size);
  }
  @include desktop-xl {
    font-size: var(--Typescale-HM-XL-size);
  }
  // @include desktop-HD {
  // font-size: var(--Typescale-HM-XXL-size);
  // }
}

.headline2,
.headline-large {
  font-family: var(--Typescale-font);
  font-weight: var(--Typescale-weight);
  font-size: var(--Typescale-HL-XS-size);
  line-height: var(--Typescale-HL-line-height);
  letter-spacing: var(--Typescale-HL-tracking);
  @include tablet-landscape {
    font-size: var(--Typescale-HL-L-size);
  }
  @include desktop-xl {
    font-size: var(--Typescale-HL-XL-size);
  }
  // @include desktop-HD {
  // font-size: var(--Typescale-HL-XXL-size);
  // }
}
