.blogMarkdown {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  @include tablet-landscape {
    gap: 0.5rem;
  }

  ul {
    padding-left: 1rem;
  }

  li {
    list-style: disc;
  }
}
