// ----- animation for the autoslider ----- //

.custom-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  min-height: 20.625rem;
  height: 51.9375rem;

  @include tablet-landscape {
    min-width: 31.89rem;
    min-height: 34.75rem;
  }

  @include desktop-xl {
    min-width: 49.31rem;
  }
}

.slider {
  width: 100%;
  overflow: hidden;
}

.slider .slide-track {
  display: flex;
  animation: scroll 13s linear infinite;
  -webkit-animation: scroll 13s linear infinite;
  width: calc(320px * 18);
  align-items: baseline;
  cursor: pointer;

  @media (min-width: 800px) {
    animation: scroll 40s linear infinite;
    -webkit-animation: scroll 40s linear infinite;
  }
  &.stopped {
    animation-play-state: paused;
    -webkit-animation-play-state: paused;
  }
}

.slide {
  width: 320px;
  height: 45px;
  display: flex;
  justify-content: center;
}
@keyframes scroll {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(calc(-320px * 8));
    transform: translateX(calc(-320px * 8));
  }
}

// ----- cards from Our Goal section ----- //

.inputGoal,
.toggles {
  position: absolute;
  width: 37px;
  height: 37px;
  bottom: 5px;
  right: 5px;
  outline: none;
  z-index: 10;
  color: var(--on-surface-dark);
  cursor: pointer;
  transition: cubic-bezier(1, 0, 0, 1);
}

.inputGoal[type="checkbox"] {
  width: 100%;
  height: 100%;
}

.inputGoal[type="checkbox"]:checked + div::before {
  content: "";
  display: block;
  position: absolute;
  text-align: center;
  width: 37px;
  height: 37px;
  bottom: 0px;
  right: 0px;
  background-color: #004b74;
  font-family: "Montserrat";

  border: 1px solid #004b74;
  padding: 0;
}

.inputGoal[type="checkbox"]:checked + div::after {
  content: "-";
  display: block;
  position: absolute;
  left: 11px;
  bottom: 3px;
  border: 1px solid transparent;
}

.inputGoal {
  opacity: 0;
}

.toggles {
  pointer-events: none;
  border-radius: 50%;
  transition: 0.5s;
  text-align: center;
  justify-content: center;
  line-height: 34px;
  font-size: 34px;
  box-shadow: 0 0 0 0px #004b74;
}

.inputGoal:checked ~ .toggles {
  box-shadow: 0 0 0 600px #004b74;
}

.card-title {
  padding: 0.625rem 1.25rem;
  color: var(--on-surface-light);
}

.card-subtitle {
  padding: 0rem 1.25rem 2rem;
  color: var(--on-surface-light);
}

.imgBx,
.details {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.5s;
}

.details {
  padding: 0.625rem 1.25rem;
  box-sizing: border-box;
  z-index: 11;
  pointer-events: none;
  opacity: 0;
  transition: 0s;

  @include tablet-landscape {
    padding: 1.25rem;
  }
}

.inputGoal:checked ~ .details {
  opacity: 1;
  transition-delay: 0.5s;
}

.details h2 {
  color: #94ccff;
  padding-bottom: 0.625rem;
}
.details h6 {
  color: #fff;
  padding-bottom: 0.625rem;

  @include tablet-landscape {
    min-height: 8rem;
  }
}

.details p {
  color: #fff;
}

// ----- cards from benefits desktop ----- //

.ul-style {
  display: none;

  @include tablet-portrait {
    display: flex;
    background-color: transparent;
    border-radius: 25px;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    list-style: none;
    align-items: center;
  }
}

.li-style {
  background-color: #dddddd;
  border-radius: 10px;
  margin-bottom: 2.5rem;
  width: 12.43rem;
  cursor: pointer;

  @include tablet-landscape {
    background-color: #dddddd;
    border-radius: 10px;
    margin-bottom: 2.5rem;
    width: 15.43rem;
    cursor: pointer;
  }
}

.image {
  width: 24px;
  height: 24px;

  //change color svg
  path {
    fill: #003352 !important;
  }
}
