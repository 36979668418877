:root {
  --Typescale-font: "Aeonik";
  --Typescale-font-medium: "Aeonik";
  --Typescale-weight: 400;

  //Font-Size
  --Typescale-DL-XS-size: 2.5rem;
  --Typescale-DM-XS-size: 2rem;
  --Typescale-DS-XS-size: 1.75rem;
  --Typescale-HL-XS-size: 1.625rem;
  --Typescale-HM-XS-size: 1.1875rem;
  --Typescale-HS-XS-size: 0.75rem;
  --Typescale-TL-XS-size: 1.5rem;
  --Typescale-TM-XS-size: 1.125rem;
  --Typescale-TS-XS-size: 0.875rem;
  --Typescale-BL-XS-size: 1rem;
  --Typescale-BM-XS-size: 0.875rem;
  --Typescale-BS-XS-size: 0.75rem;
  --Typescale-LL-XS-size: 0.625rem;
  --Typescale-LM-XS-size: 0.5625rem;
  --Typescale-LS-XS-size: 0.5rem;

  --Typescale-DL-L-size: 2.875rem;
  --Typescale-DM-L-size: 2.125rem;
  --Typescale-DS-L-size: 2rem;
  --Typescale-HL-L-size: 2.375rem;
  --Typescale-HM-L-size: 1.5rem;
  --Typescale-HS-L-size: 1rem;
  --Typescale-TL-L-size: 1.6875rem;
  --Typescale-TM-L-size: 1.3125rem;
  --Typescale-TS-L-size: 1.0625rem;
  --Typescale-BL-L-size: 1.1875rem;
  --Typescale-BM-L-size: 1.0625rem;
  --Typescale-BS-L-size: 0.9375rem;
  --Typescale-LL-L-size: 0.75rem;
  --Typescale-LM-L-size: 0.688rem;
  --Typescale-LS-L-size: 0.625rem;

  --Typescale-DL-XL-size: 4.5rem;
  --Typescale-DM-XL-size: 4rem;
  --Typescale-DS-XL-size: 3.5rem;
  --Typescale-HL-XL-size: 2.625rem;
  --Typescale-HM-XL-size: 1.75rem;
  --Typescale-HS-XL-size: 1.125rem;
  --Typescale-TL-XL-size: 1.75rem;
  --Typescale-TM-XL-size: 1.375rem;
  --Typescale-TS-XL-size: 1.125rem;
  --Typescale-BL-XL-size: 1.25rem;
  --Typescale-BM-XL-size: 1.125rem;
  --Typescale-BS-XL-size: 1rem;
  --Typescale-LL-XL-size: 0.875;
  --Typescale-LM-XL-size: 0.75rem;
  --Typescale-LS-XL-size: 0.5625rem;

  --Typescale-DL-XXL-size: 6rem;
  --Typescale-DM-XXL-size: 4.5rem;
  --Typescale-DS-XXL-size: 4rem;
  --Typescale-HL-XXL-size: 2.8rem;
  --Typescale-HM-XXL-size: 2.25rem;
  --Typescale-HS-XXL-size: 1.5rem;
  --Typescale-TL-XXL-size: 2.25rem;
  --Typescale-TM-XXL-size: 1.75rem;
  --Typescale-TS-XXL-size: 1.25rem;
  --Typescale-BL-XXL-size: 1.125rem;
  --Typescale-BM-XXL-size: 1rem;
  --Typescale-BS-XXL-size: 0.875rem;
  --Typescale-LL-XXL-size: 1.25rem;
  --Typescale-LM-XXL-size: 1.125rem;
  --Typescale-LS-XXL-size: 1rem;

  //Line-Height
  --Typescale-DL-line-height: 100%;
  --Typescale-DM-line-height: 100%;
  --Typescale-DS-line-height: 100%;
  --Typescale-HL-line-height: 100%;
  --Typescale-HM-line-height: 100%;
  --Typescale-HS-line-height: 100%;
  --Typescale-TL-line-height: 130%;
  --Typescale-TM-line-height: 130%;
  --Typescale-TS-line-height: 130%;
  --Typescale-BL-line-height: 130%;
  --Typescale-BM-line-height: 130%;
  --Typescale-BS-line-height: 130%;
  --Typescale-LL-line-height: 100%;
  --Typescale-LM-line-height: 100%;
  --Typescale-LS-line-height: 100%;

  //Letter Spacing
  --Typescale-DL-tracking: -0.5px;
  --Typescale-DM-tracking: -0.5px;
  --Typescale-DS-tracking: -0.5px;
  --Typescale-HL-tracking: -0.5px;
  --Typescale-HM-tracking: 0px;
  --Typescale-HS-tracking: 0px;
  --Typescale-TL-tracking: 0.1px;
  --Typescale-TM-tracking: 0.1px;
  --Typescale-TS-tracking: 0.1px;
  --Typescale-BL-tracking: 0.25px;
  --Typescale-BM-tracking: 0.25px;
  --Typescale-BS-tracking: 0.25px;
  --Typescale-LL-tracking: 0.5px;
  --Typescale-LM-tracking: 0.5px;
  --Typescale-LS-tracking: 0.5px;
}
