:root {
  //light
  --primary-light: #006398;
  --on-primary-light: #ffffff;
  --primary-container-light: #cde5ff;
  --on-primary-container-light: #001d32;
  --secondary-light: #006a64;
  --on-secondary-light: #ffffff;
  --secondary-container-light: #71f7ec;
  --on-secondary-container-light: #00201e;
  --tertiary-light: #8d4f00;
  --on-tertiary-light: #ffffff;
  --tertiary-container-light: #ffdcc0;
  --on-tertiary-container-light: #2d1600;
  --error-light: #ba1a1a;
  --on-error-light: #ffffff;
  --error-container-light: #ffdad6;
  --on-error-container-light: #410002;
  --outline-light: #72787e;
  --background-light: #fcfcff;
  --on-background-light: #1a1c1e;
  --surface-light: #fcfcff;
  --on-surface-light: #1a1c1e;
  --surface-variant-light: #dee3eb;
  --on-surface-variant-light: #42474e;
  --inverse-surface-light: #2f3033;
  --inverse-on-surface-light: #f0f0f4;
  //dark
  --primary-dark: #94ccff;
  --on-primary-dark: #003352;
  --primary-container-dark: #004b74;
  --on-primary-container-dark: #cde5ff;
  --secondary-dark: #50dbd0;
  --on-secondary-dark: #003734;
  --secondary-container-dark: #00504b;
  --on-secondary-container-dark: #71f7ec;
  --tertiary-dark: #ffb876;
  --on-tertiary-dark: #4b2800;
  --tertiary-container-dark: #6b3b00;
  --on-tertiary-container-dark: #ffdcc0;
  --error-dark: #ffb4ab;
  --on-error-dark: #690005;
  --error-container-dark: #93000a;
  --on-error-container-dark: #ffdad6;
  --outline-dark: #8c9198;
  --background-dark: #1a1c1e;
  --on-background-dark: #e2e2e5;
  --surface-dark: #1a1c1e;
  --on-surface-dark: #e2e2e5;
  --surface-variant-dark: #42474e;
  --on-surface-variant-dark: #c2c7cf;
  --inverse-surface-dark: #e2e2e5;
  --inverse-on-surface-dark: #1a1c1e;

  //disabled
  --disabled: #72787e;
  //custom colors
  --surface-30-light: #45474a;
  --surface-80-light: #c6c6c9;
  --surface-90-light: #e2e2e5;
  --surface-95-light: #f0f0f4;
  --surface-100-light: #fcfcff;
  --surface-30-dark: #c6c6c9;
  --surface-80-dark: #202529; //falta
  --surface-90-dark: #242a30; //falta
  --surface-95-dark: #272f37; //listo

  --primary-40: #006398;
  --primary-60: #4397d6;
  --primary-80: #94ccff;

  --secondary-20: #003734;
  --secondary-30: #006a64;
  --secondary-40: #009d94;
  --secondary-50: #00d0c4;
  --secondary-70: #25beb5;
  --secondary-80: #50dbd0;

  --red-shadow-light: #ffdad6;
  --red-shadow-dark: #9c4140;

  //custom bg
  --color-background-dark: #fcfcff;
  --color-background-light: #1a1c1e;
  --svg-background-dark: #ebf8ff;
  --svg-background-light: transparent;

  //svg bg colors
  --svg-background-light: #b2bac2;
  --svg-background-dark: #4c606f;

  //cake graph

  --tertiary-light: #ed9841;
  --neutral-30-light: #45474a;
  --tertiary-dark: #ed9841;
  --neutral-30-dark: #e2e2e5;

  //tertiary colors

  --tertiary-99: #fffbff;

  //tablet sates - light
  --tablet-sates-0-ligth: #00a65e;
  --tablet-sates-1-ligth: #ffb871;
  --tablet-sates-2-ligth: #de3730;
  --tablet-sates-3-ligth: #72787e;

  //tablet sates - dark
  --tablet-sates-0-dark: #00a65e;
  --tablet-sates-1-dark: #ffb871;
  --tablet-sates-2-dark: #de3730;
  --tablet-sates-3-dark: #72787e;

  .light {
    --primary: var(--primary-light);
    --on-primary: var(--on-primary-light);
    --primary-container: var(--primary-container-light);
    --on-primary-container: var(--on-primary-container-light);
    --secondary: var(--secondary-light);
    --on-secondary: var(--on-secondary-light);
    --secondary-container: var(--secondary-container-light);
    --on-secondary-container: var(--on-secondary-container-light);
    --tertiary: var(--tertiary-light);
    --on-tertiary: var(--on-tertiary-light);
    --tertiary-container: var(--tertiary-container-light);
    --on-tertiary-container: var(--on-tertiary-container-light);
    --error: var(--error-light);
    --on-error: var(--on-error-light);
    --error-container: var(--error-container-light);
    --on-error-container: var(--on-error-container-light);
    --outline: var(--outline-light);
    --background: var(--background-light);
    --on-background: var(--on-background-light);
    --surface: var(--surface-light);
    --on-surface: var(--on-surface-light);
    --surface-variant: var(--surface-variant-light);
    --on-surface-variant: var(--on-surface-variant-light);
    --inverse-surface: var(--inverse-surface-light);
    --inverse-on-surface: var(--inverse-on-surface-light);
    --surface-30: var(--surface-30-light);
    --surface-80: var(--surface-80-light);
    --surface-85: var(--surface-85-light);
    --surface-90: var(--surface-90-light);
    --surface-95: var(--surface-95-light);
    --surface-4: var(--surface-4-light);
    --surface-5: var(--surface-5-light);
    --color-background: var(--color-background-light);
    --svg-background: var(--svg-background-light);

    --tertiary: var(--tertiary-light);
    --neutral-30: var(--neutral-30-light);
    --svg-background: var(--svg-background-light);
    --red-shadow: var(--red-shadow-light);

    //tablet
    --tablet-sates-0: var(--tablet-sates-0-light);
    --tablet-sates-1: var(--tablet-sates-1-light);
    --tablet-sates-2: var(--tablet-sates-2-light);
    --tablet-sates-3: var(--tablet-sates-3-light);
  }

  .dark {
    --primary: var(--primary-dark);
    --on-primary: var(--on-primary-dark);
    --primary-container: var(--primary-container-dark);
    --on-primary-container: var(--on-primary-container-dark);
    --secondary: var(--secondary-dark);
    --on-secondary: var(--on-secondary-dark);
    --secondary-container: var(--secondary-container-dark);
    --on-secondary-container: var(--on-secondary-container-dark);
    --tertiary: var(--tertiary-dark);
    --on-tertiary: var(--on-tertiary-dark);
    --tertiary-container: var(--tertiary-container-dark);
    --on-tertiary-container: var(--on-tertiary-container-dark);
    --error: var(--error-dark);
    --on-error: var(--on-error-dark);
    --error-container: var(--error-container-dark);
    --on-error-container: var(--on-error-container-dark);
    --outline: var(--outline-dark);
    --background: var(--background-dark);
    --on-background: var(--on-background-dark);
    --surface: var(--surface-dark);
    --on-surface: var(--on-surface-dark);
    --surface-variant: var(--surface-variant-dark);
    --on-surface-variant: var(--on-surface-variant-dark);
    --inverse-surface: var(--inverse-surface-dark);
    --inverse-on-surface: var(--inverse-on-surface-dark);
    --surface-30: var(--surface-30-dark);
    --surface-80: var(--surface-80-dark);
    --surface-85: var(--surface-85-dark);
    --surface-90: var(--surface-90-dark);
    --surface-95: var(--surface-95-dark);
    --surface-4: var(--surface-4-dark);
    --surface-5: var(--surface-5-dark);
    --color-background: var(--color-background-dark);
    --svg-background: var(--svg-background-dark);
    --tertiary: var(--tertiary-dark);
    --neutral-30: var(--neutral-30-dark);
    --svg-background: var(--svg-background-dark);
    --red-shadow: var(--red-shadow-dark);

    //tablet
    --tablet-sates-0: var(--tablet-sates-0-dark);
    --tablet-sates-1: var(--tablet-sates-1-dark);
    --tablet-sates-2: var(--tablet-sates-2-dark);
    --tablet-sates-3: var(--tablet-sates-3-dark);
  }
}
