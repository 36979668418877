@keyframes scale {
  from {
    transform: scale(0.9);
  }
  to {
    transform: scale(1);
  }
}

@keyframes rocketMovement {
  from {
    transform: translateX(0);
  }
  to {
    transform: translate(-6%, 3%);
  }
}

@keyframes jump {
  from {
    transform: translateY(0);
  }
  to {
    transform: translate(0, 3%);
  }
}

.scaled {
  transform-origin: center;
  animation: 0.7s ease-in-out infinite alternate 1s scale;
}

.scaled2 {
  transform-origin: center;
  animation: 0.7s ease-in-out infinite alternate 5s scale;
}

.jump {
  transform-origin: center;
  animation: 0.7s ease-in-out infinite alternate 5s jump;
}
